<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <g-card page-title="Admin List" page-icon="mdi-account-multiple">
    <g-list-page
        elevation="elevation-0"
        :headers="headers"
        stateendpoint="user.adminUsers"
        @loadData="loadData"
        @editItem="editItem"
        @deleteItem="deleteItem"
        @destroyItem="destroyItem"
        @switchHandler="switchHandler"
        @restoreItem="restoreItem"
    >
      <template v-slot:overlay>
        <page-overlay :overlay="isOverlay" />
      </template>
      <template v-slot:top>
        <v-row>
          <v-col cols="12" md="6" sm="8">
            <g-p-search
                hint="Minimum two characters needed to search. Columns(name)"
                @applySearch="handleSearch"
            />
          </v-col>
          <v-col cols="12" md="6" sm="4">
            <add-button
                text="Admin User"
                :toolbar="true"
                @onButtonClick="openPopUp"
                class="ma-2 justify-end"
            />
          </v-col>
        </v-row>
      </template>
    </g-list-page>
    <component
        is="AdminUserPopup"
        ref="component"
        :basic-data="basicData"
        :item="editedItem"
        :show="showPopup"
        @closePopupHandler="closePopupHandler"
        @onPopupShow="openPopUp"
        @saveItemHandler="saveItemHandler"
    />
    <confirm-popup
        :show="showConfirm"
        confirm-event="onConfirmItem"
        @closePopupHandler="showConfirm = false"
        @onConfirmItem="confirmEvent(currentSelectedItem, true)"
    >
      <div>
        <!-- eslint-disable-next-line  -->
        <span>{{ confirmTxt }}</span>
      </div>
    </confirm-popup>
  </g-card>
</template>
<script>
import AdminUserPopup from "../components/popups/AdminUserPopup";
import ListMixins from "../components/mixins/list/List";
import GCard from "../components/GCard";
import ConfirmPopup from "../components/popups/ConfirmPopup";
import PageOverlay from "../components/PageOverlay";
import GPSearch from "../components/GPSearch";
import GListPage from "../components/list/GListPage";
import AddButton from "../components/buttons/AddButton";

export default {
  name: 'Users',
  metaInfo: {
    title: 'rabbiitfirm.com',
    titleTemplate: 'Dashboard - Admin List | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {
    AddButton,
    GListPage,
    GPSearch,
    PageOverlay,
    ConfirmPopup,
    GCard,
    AdminUserPopup
  },
  mixins: [ListMixins],
  data() {
    return {
      headers: [
        {
          text: "Name",
          value: "name"
        },
        {
          text: "email",
          value: "email"
        },
        {
          text: "Status",
          value: "isActive",
          cellClass: "center-switch",
          align: "center"
        },
        {
          text: "",
          value: "actions",
          sortable: false
        }
      ],
      actions: {
        load: "user/loadAdminUsers",
        store: "user/register",
        update: "user/updateAdmin",
        remove: "user/remove",
        destroy: "user/destroy",
        restore: "user/restore",
      },
      basicData: {
        name: "",
        email: "",
        password: "",
        passwordConfirmation: "",
        role: 2,
        status: 1
      }
    }
  },
}
</script>
